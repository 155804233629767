export default {
  setLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
  setPermissions(state, payload) {
    state.permissions = payload;
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  setUserDetails(state, payload) {
    state.userDetails = payload;
  },

  setBrandUrl(state, payload) {
    state.brandUrl = payload;
  },
  setSettings(state, payload) {
    state.settings = payload;
  }
};
