<template>
  <v-card
    color="grey lighten-3"
    width="100%"
    height="200"
    flat
    class="pa-2 d-flex flex-column justify-center align-center"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <input
      id="assetsFieldHandle"
      type="file"
      style="opacity: 0; position: absolute; height: 100%; width: 100%;"
      @change="onChange"
      ref="file"
    />
    <label
      for="assetsFieldHandle"
      class="d-flex flex-column justify-center align-center"
    >
      <v-icon>mdi-cloud-upload</v-icon>
      <span class="mt-2">
        Ziehen Sie die Datei in den Kasten, oder <b>klicken</b> Sie.
      </span>
    </label>
    <v-chip color="primary" dark v-if="file" class="mt-2">
      {{ file.name }}
      <v-avatar right @click="file = null">
        <v-icon>mdi-close</v-icon>
      </v-avatar>
    </v-chip>
  </v-card>
</template>

<script>
export default {
  props: ["fileProp"],
  data: () => ({
    file: null
  }),
  mounted() {
    this.file = this.fileProp;
  },
  watch: {
    fileProp: function(val) {
      this.file = val;
    },
    file: function(val) {
      this.$emit("pass", val);
    }
  },
  methods: {
    onChange() {
      // console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];
    },
    remove() {
      this.file = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("lighten-4")) {
        event.currentTarget.classList.add("lighten-4");
        event.currentTarget.classList.remove("lighten-3");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("lighten-4");
      event.currentTarget.classList.add("lighten-3");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("lighten-3");
      event.currentTarget.classList.remove("darken-4");
    }
  }
};
</script>

<style scoped></style>
