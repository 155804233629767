<template>
  <div>
    <span v-if="currentTime === 'morning'">
      Guten Morgen,
    </span>
    <span v-else-if="currentTime === 'evening'">
      Guten Abend,
    </span>
    <span v-else-if="currentTime === 'night'">
      Einen schönen nächtlichen Spaziergang,
    </span>
    <span v-else>
      Guten Tag,
    </span>
    <span class="ml-2">{{ userDetails.name_reverse }}!</span>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {
    currentTime: function() {
      const format = "H:mm:ss";
      let time = this.$moment(),
        morningBefore = this.$moment("05:00:00", format),
        morningAfter = this.$moment("11:59:59", format),
        eveningBefore = this.$moment("17:00:00", format),
        eveningAfter = this.$moment("22:29:59", format),
        nightBefore = this.$moment("22:30:00", format),
        nightAfter = this.$moment("04:59:59", format);

      let res = null;
      if (time.isBetween(morningBefore, morningAfter)) {
        res = "morning";
      } else if (time.isBetween(eveningBefore, eveningAfter)) {
        res = "evening";
      } else if (time.isBetween(nightBefore, nightAfter)) {
        res = "night";
      }
      return res;
    },
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
