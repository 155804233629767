<template>
  <div class="mb-4">
    <v-card>
      <v-list-item class="primary" dark two-line>
        <v-list-item-action v-if="inquiry.is_extendable">
          <v-btn icon style="cursor: move;" class="handle">
            <v-icon>mdi-drag</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ sectionProp.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="sectionProp.description">
            {{ sectionProp.description }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="sectionProp.is_autorelease" class="mt-1">
            <v-chip small color="black" dark>
              <v-icon small left>
                mdi-lock-open-outline
              </v-icon>
              <span v-if="!sectionProp.released_at">
                Automatische Freigabe
              </span>
              <span v-else-if="!isTemplate">
                Freigabe am {{ sectionProp.released_at | moment("DD.MM.YYYY") }}
              </span>
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="inquiry.is_extendable">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <inquiries-wizard-layout-section-dialog
                  :section-prop="sectionProp"
                  :is-template="isTemplate"
                />
              </div>
            </template>
            <span>Bearbeiten</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-action v-if="inquiry.is_extendable">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="destroySection"
                :loading="loading"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Entfernen</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
      <ui-secondary-divider />
      <Draggable
        v-model="sectionProp.children"
        group="children"
        handle=".handle"
        @end="patchSection"
      >
        <template v-for="child in sectionProp.children">
          <inquiries-wizard-layout-child :key="child.id" :child-prop="child" />
        </template>
      </Draggable>
      <v-card-actions class="pa-4" v-if="inquiry.is_extendable">
        <v-spacer />
        <inquiries-wizard-layout-document-dialog
          v-if="sectionProp.id"
          :section-id="sectionProp.id"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    },
    sectionProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    // functional
    loading: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["currentInquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      destroyInquiryItem: "inquiryItem/destroy",
      showCurrentInquiry: "currentInquiry/showInquiry"
    }),
    destroySection() {
      this.loading = true;
      this.destroyInquiryItem(this.sectionProp.id)
        .then(() => {
          this.showCurrentInquiry(this.$route.params.id);
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Entfernen des Abschnitts ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    patchSection() {
      this.$store.dispatch("inquiryItem/patch", {
        id: this.sectionProp.id,
        payload: this.sectionProp,
        onlyChildren: true
      });
    }
  }
};
</script>

<style scoped></style>
