import axios from "axios";

export default {
  showInquiry(ctx, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/inquiries/" + id)
        .then(response => {
          ctx.commit("setCurrentInquiry", response.data.inquiry);
          ctx.dispatch("indexAvailableDocuments");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  indexAvailableDocuments(ctx) {
    return new Promise((resolve, reject) => {
      let id = ctx.getters["currentInquiry"].id;
      axios
        .get("/documents?tree=true&inquiry=" + id)
        .then(response => {
          ctx.commit("setAvailableDocuments", response.data.documents);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
