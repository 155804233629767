<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="accent darken-3" v-bind="attrs" v-on="on">
        Hilfe
        <v-icon right dark>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Hilfestellung">
      <template v-slot:text>
        {{
          $hasPermissions("consultant permission")
            ? inquiryItem.help
            : inquiryItem.help_customer
        }}
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text @click="close">
          Okay
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    itemProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    item: {},

    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    inquiryItem() {
      return this.$store.getters["inquiryItem/inquiryItem"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    itemProp(val) {
      this.section = val;
    }
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({}),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
    }
  }
};
</script>

<style scoped></style>
