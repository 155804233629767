var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('inquiries-create-dialog')],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('router-link',{staticClass:"custom-link",attrs:{"to":{
          name: 'Inquiries',
          query: { type: 'assigned', view: 'column' }
        }}},[_c('v-card',{staticClass:"d-flex justify-center align-center",attrs:{"height":"100%"}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-radiobox-marked")]),_vm._v(" "+_vm._s(_vm.open)+" / "+_vm._s(_vm.total)+" Offen ")],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('router-link',{staticClass:"custom-link",attrs:{"to":{
          name: 'Inquiries',
          query: { type: 'assigned', view: 'column' }
        }}},[_c('v-card',{staticClass:"d-flex justify-center align-center",attrs:{"height":"100%"}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-briefcase-outline")]),_vm._v(" "+_vm._s(_vm.in_work)+" / "+_vm._s(_vm.total)+" in Arbeit ")],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('router-link',{staticClass:"custom-link",attrs:{"to":{
          name: 'Inquiries',
          query: { type: 'assigned', view: 'column' }
        }}},[_c('v-card',{staticClass:"d-flex justify-center align-center",attrs:{"height":"100%"}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v(" "+_vm._s(_vm.finished)+" / "+_vm._s(_vm.total)+" Fertig ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }