<template>
  <v-list-item>
    <v-list-item-action v-if="inquiry.is_extendable">
      <v-btn icon style="cursor: move;" class="handle">
        <v-icon>mdi-drag</v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        {{ childProp.name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ childProp.description }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="mt-1"
        v-if="childProp.attachable && childProp.attachable.is_outbound"
      >
        <v-chip small color="primary" dark>
          <v-icon small left>
            mdi-share-all-outline
          </v-icon>
          Ausgehend
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="inquiry.is_extendable">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="destroyChild"
            :loading="destroyLoading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Entfernen</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    childProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    destroyLoading: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["currentInquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      showCurrentInquiry: "currentInquiry/showInquiry"
    }),
    destroyChild() {
      this.destroyLoading = true;
      this.axios
        .delete("inquiry-items/" + this.childProp.id)
        .then(() => {
          this.showCurrentInquiry(this.$route.params.id);
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Entfernen der Anforderung ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.destroyLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
