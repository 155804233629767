<template>
  <div style="margin-top: 128px;">
    <v-card
      flat
      color="transparent"
      v-if="$hasPermissions('consultant permission')"
    >
      <v-row align="end" class="pb-4" v-if="!isShare">
        <v-col cols="12" md="9">
          <inquiry-items-show-share-dialog />
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="9"
          sm="9"
          xs="12"
          class="d-flex flex-column justify-space-between"
        >
          <div>
            <v-card-title>
              {{ inquiryItem.name }}
            </v-card-title>
            <v-card-text>
              {{ inquiryItem.description }}
            </v-card-text>
          </div>
          <div>
            <v-card-actions
              class="pa-4"
              v-if="$hasPermissions('consultant permission') && inquiryItem"
            >
              <div class="mr-2">
                <inquiry-items-show-request-again-dialog
                  v-if="
                    inquiryItem && inquiryItem.currentUploadRequest && !isShare
                  "
                />
              </div>
              <inquiry-items-show-help-dialog v-if="inquiryItem.help" />
            </v-card-actions>
            <v-card-actions
              class="pa-4"
              v-else-if="
                $hasPermissions('customer permission') &&
                  inquiryItem.help_customer
              "
            >
              <inquiry-items-show-help-dialog />
            </v-card-actions>
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="3" class="hidden-xs-only">
          <img
            src="@/assets/inquiry-items/documents.svg"
            alt="Dokumente"
            style="width: 100%; margin-top: -128px"
            class="pr-2"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    isShare: {
      type: Boolean,
      default: false
    },
    sharePermission: {
      type: String,
      default: "read"
    }
  },
  data: () => ({}),
  computed: {
    inquiryItem() {
      return this.$store.getters["inquiryItem/inquiryItem"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
