<template>
  <div>
    <div class="text-overline mb-4">Aktueller Aufruf</div>
    <div class="mb-8">
      <inquiry-items-show-upload-requests-current-item :is-share="isShare" />
    </div>
    <div class="text-overline mb-4">Vergangene Aufrufe</div>
    <div>
      <template v-for="uploadRequest in inquiryItem.uploadRequests">
        <inquiry-items-show-upload-requests-past-item
          :upload-request="uploadRequest"
          :key="uploadRequest.id"
          class="mb-2"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShare: {
      type: Boolean,
      default: false
    },
    sharePermission: {
      type: String,
      default: "read"
    }
  },
  data: () => ({}),
  computed: {
    inquiryItem() {
      return this.$store.getters["inquiryItem/inquiryItem"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
