import Vue from "vue";
import Vuex from "vuex";

/**
 * modules
 */
import application from "./application";
import currentInquiry from "./currentInquiry";
import inquiryDashboard from "./inquiryDashboard";
import state from "./state";
import user from "./user";

/**
 * model modules
 */

import inquiry from "./_models/inquiry";
import inquiryItem from "./_models/inquiryItem";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    application,
    currentInquiry,
    inquiryDashboard,
    state,
    user,

    // models
    inquiry,
    inquiryItem
  }
});
