<template>
  <div>
    <!--    <inquiries-overview-old />-->
    <v-tabs
      color="black"
      background-color="transparent"
      fixed-tabs
      v-if="inquiryDashboardIndex"
    >
      <v-tab
        exact
        :to="{
          name: 'Inquiries',
          query: { type: 'assigned', view: 'column' }
        }"
      >
        Mir zugewiesen ({{
          inquiryDashboardIndex.assignedInquiries
            ? inquiryDashboardIndex.assignedInquiries.length
            : 0
        }})
        <util-info-tooltip>
          <template v-slot:text>
            Alle Ihnen zugewiesene Anfragen. <br />
            Entweder durch Sie, oder durch einen anderen Berater erstellt.
          </template>
        </util-info-tooltip>
      </v-tab>
      <v-tab
        exact
        :to="{
          name: 'Inquiries',
          query: { type: 'sharedWith', view: 'list' }
        }"
      >
        Mit mir geteilt ({{
          inquiryDashboardIndex.sharedWithUser
            ? inquiryDashboardIndex.sharedWithUser.length
            : 0
        }})
        <util-info-tooltip>
          <!--<template v-slot:title>
              Beispiel
            </template>-->
          <template v-slot:text>
            Alle Anfragen die durch einen Berater mit Ihnen geteilt wurden.
          </template>
        </util-info-tooltip>
      </v-tab>
      <v-tab
        exact
        :to="{
          name: 'Inquiries',
          query: { type: 'assignedToTeams', view: 'list' }
        }"
      >
        Meinen Teams zugewiesen ({{
          inquiryDashboardIndex.assignedToTeams
            ? inquiryDashboardIndex.assignedToTeams.length
            : 0
        }})
        <util-info-tooltip>
          <template v-slot:text>
            Alle Anfragen die mit Ihren Teams zur gemeinsamen Bearbeitung
            zugewiesen wurden.
          </template>
        </util-info-tooltip>
      </v-tab>
      <v-tab
        exact
        :to="{
          name: 'Inquiries',
          query: { type: 'sharedWithTeams', view: 'list' }
        }"
      >
        Mit meinen Teams geteilt ({{
          inquiryDashboardIndex.sharedWithTeam
            ? inquiryDashboardIndex.sharedWithTeam.length
            : 0
        }})
        <util-info-tooltip>
          <template v-slot:text>
            Alle Anfragen die mit Ihren Teams zur gemeinsamen Bearbeitung
            geteilt wurden.
          </template>
        </util-info-tooltip>
      </v-tab>
      <v-tab
        exact
        :to="{ name: 'Inquiries', query: { type: 'drafts', view: 'list' } }"
      >
        Entwürfe ({{
          inquiryDashboardIndex.assignedInquiries_draft
            ? inquiryDashboardIndex.assignedInquiries_draft.length
            : 0
        }})
        <util-info-tooltip>
          <template v-slot:text>
            Alle Entwürfe für Anfragen sind hier einsehbar.
          </template>
        </util-info-tooltip>
      </v-tab>
    </v-tabs>
    <!--Search & Sort-->
    <v-row class="mt-5">
      <v-col cols md="5">
        <v-btn-toggle>
          <v-btn
            text
            height="38px"
            :to="{ query: { type: $route.query.type, view: 'column' } }"
            exact
            active
            :disabled="tab !== 0"
          >
            Spaltenansicht
          </v-btn>
          <v-btn
            text
            height="38px"
            :to="{ query: { type: $route.query.type, view: 'list' } }"
            exact
          >
            Listenansicht
          </v-btn>
        </v-btn-toggle>
        <span class="ml-4">
          <inquiries-create-dialog use-type="dashboard" />
        </span>
      </v-col>
      <v-col cols md="4">
        <v-text-field
          v-if="$route.query.view !== 'column'"
          solo
          solo-inverted
          flat
          placeholder="Suchen"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          v-model="search"
        />
      </v-col>
      <v-col
        cols
        :md="$route.query.view !== 'column' ? 2 : 3"
        v-if="$hasPermissions('consultant permission')"
      >
        <v-select
          solo
          solo-inverted
          flat
          placeholder="Sortieren"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          :items="$route.query.view === 'column' ? keysColumn : keys"
          v-model="sortBy"
        />
      </v-col>
      <v-col
        cols
        md="1"
        v-if="
          $hasPermissions('consultant permission') &&
            $route.query.view !== 'column'
        "
      >
        <v-btn-toggle v-model="sortDesc" mandatory style="width: 50%">
          <v-btn
            block
            height="38px"
            depressed
            color="grey lighten-3"
            :value="false"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn
            block
            height="38px"
            depressed
            color="grey lighten-3"
            :value="true"
          >
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab" class="mt-5 transparent">
      <v-tab-item>
        <div>
          <inquiries-column-view
            v-if="$route.query.view === 'column'"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            :search="search"
          />
          <inquiries-list-view
            v-if="$route.query.view === 'list'"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            :search="search"
          />
        </div>
      </v-tab-item>
      <v-tab-item>
        <inquiries-list-view
          v-if="$route.query.view === 'list'"
          :sortBy="sortBy"
          :sortDesc="sortDesc"
          :search="search"
          :isShare="true"
        />
      </v-tab-item>
      <v-tab-item>
        <inquiries-list-view
          v-if="$route.query.view === 'list'"
          :sortBy="sortBy"
          :sortDesc="sortDesc"
          :search="search"
        />
      </v-tab-item>
      <v-tab-item>
        <inquiries-list-view
          v-if="$route.query.view === 'list'"
          :sortBy="sortBy"
          :sortDesc="sortDesc"
          :search="search"
          :isShare="true"
        />
      </v-tab-item>
      <v-tab-item>
        <inquiries-list-view
          v-if="$route.query.view === 'list'"
          :sortBy="sortBy"
          :sortDesc="sortDesc"
          :search="search"
          :isDraft="true"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    search: "",
    sortDesc: true,
    sortBy: "last_customer_action_at",
    keys: [
      { value: "last_customer_action_at", text: "Letzte Aktualisierung" },
      { value: "name", text: "Name" },
      { value: "customer.name", text: "Kunde" },
      { value: "fulfillment_ratio", text: "Vollständigkeit" }
    ],
    keysColumn: [
      { value: "last_customer_action_at", text: "Letzte Aktualisierung" },
      { value: "fulfillment_ratio", text: "Vollständigkeit" }
    ],
    assignedInquiries: 0,
    assignedInquiries_draft: 0,
    sharedWithUser: 0,
    sharedWithTeam: 0,
    assignedToTeams: 0
  }),
  computed: {
    inquiryDashboardIndex() {
      return this.$store.getters["inquiryDashboard/index"];
    },
    tab() {
      switch (this.$route.query.type) {
        case "assigned":
          return 0;
        case "sharedWith":
          return 1;
        case "assignedToTeams":
          return 2;
        case "sharedWithTeams":
          return 3;
        case "drafts":
          return 4;
      }
      return 0;
    }
  },
  watch: {},
  created() {
    if (!this.$route.query.view) {
      this.$router.replace({ query: { view: "column", type: "assigned" } });
      this.$route.query.view = "column";
    }
  },
  methods: {}
};
</script>

<style scoped></style>
