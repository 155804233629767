import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "core-js/stable";
import "regenerator-runtime/runtime";

/********Import and use packages********/
/**
 * Axios
 */
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
// get backend url
Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.axios.defaults.headers;
// attach bearer token
Vue.axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function(error) {
    console.log("ERROR ALARM");
    return Promise.reject(error);
  }
);

/**
 * Fragment
 */
import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

/**
 * Moment
 */
import moment from "moment";
moment.locale("de");
Vue.prototype.$moment = moment;
Vue.use(require("vue-moment"), {
  moment
});
/**
 * Snotify
 */
import Snotify, { SnotifyPosition } from "vue-snotify";

const options = {
  toast: {
    position: SnotifyPosition.rightBottom
  }
};

Vue.use(Snotify, options);

/**
 * SweetAlertIcons
 */

import SweetAlertIcons from "vue-sweetalert-icons";

Vue.use(SweetAlertIcons);

/**
 * Vuedraggable
 */

import draggable from "vuedraggable";

Vue.component("Draggable", draggable);

/**
 * VueProgress
 */
import VueProgressBar from "vue-progressbar";

const progressBarOptions = {
  color: "#000000",
  failedColor: "#d63030",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

Vue.use(VueProgressBar, progressBarOptions);
/********helpers********/
/**
 * Register all components globally (parentFolder-folder-componentName)
 */
import registerComponents from "@/helpers/registerComponents";
import hasPermission from "@/helpers/hasPermissions";
registerComponents();

/**
 * Permissions
 */
Vue.prototype.$hasPermissions = hasPermission;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
