<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <inquiries-create-dialog />
      </v-col>
      <v-col cols="12" md="3">
        <router-link
          :to="{
            name: 'Inquiries',
            query: { type: 'assigned', view: 'column' }
          }"
          class="custom-link"
        >
          <v-card height="100%" class="d-flex justify-center align-center">
            <v-card-title>
              <v-icon left>mdi-radiobox-marked</v-icon>
              {{ open }} / {{ total }} Offen
            </v-card-title>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="12" md="3">
        <router-link
          :to="{
            name: 'Inquiries',
            query: { type: 'assigned', view: 'column' }
          }"
          class="custom-link"
        >
          <v-card height="100%" class="d-flex justify-center align-center">
            <v-card-title>
              <v-icon left>mdi-briefcase-outline</v-icon>
              {{ in_work }} / {{ total }} in Arbeit
            </v-card-title>
          </v-card>
        </router-link>
      </v-col>
      <v-col cols="12" md="3">
        <router-link
          :to="{
            name: 'Inquiries',
            query: { type: 'assigned', view: 'column' }
          }"
          class="custom-link"
        >
          <v-card height="100%" class="d-flex justify-center align-center">
            <v-card-title>
              <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
              {{ finished }} / {{ total }} Fertig
            </v-card-title>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    open: 0,
    in_work: 0,
    finished: 0,
    total: 0
  }),
  computed: {},
  watch: {},
  created() {
    this.indexStartView();
  },
  methods: {
    indexStartView() {
      this.axios
        .get("/dashboard/inquiries?view=startCount")
        .then(res => {
          this.open = res.data.open;
          this.in_work = res.data.in_work;
          this.finished = res.data.finished;
          this.total = res.data.total;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
