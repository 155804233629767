<template>
  <v-card>
    <v-card-title>Ordner erstellen</v-card-title>
    <v-card-text>
      <v-form
        lazy-validation
        v-model="isValid"
        ref="form"
        @submit.prevent="storeGroup"
      >
        <v-text-field
          v-model="group.name"
          filled
          label="Name"
          :rules="[rules.required, rules.short_text]"
          :counter="280"
        />
        <v-textarea
          v-model="group.description"
          filled
          label="Beschreibung"
          :rules="[rules.long_text]"
          :counter="6000"
        />
        <div class="d-flex justify-end">
          <v-btn text @click="$emit('dialog')">Abbrechen</v-btn>
          <v-btn
            color="primary"
            class="ml-2"
            type="submit"
            :disabled="!isValid"
          >
            Speichern
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["typeProp"],
  data: () => ({
    isValid: false,
    active: false,
    group: {
      type: "",
      name: "",
      description: ""
    }
  }),
  mounted() {
    this.group.type = this.typeProp;
  },
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  methods: {
    storeGroup() {
      if (this.$refs.form.validate()) {
        this.axios
          .post("/groups", {
            group: this.group
          })
          .then(() => {
            this.$snotify.success("Erfolgreich gespeichert.");
            this.$emit("dialog");
          })
          .catch(e => {
            console.log(e);
            this.$snotify.error("Beim Speichern ist ein Fehler aufgetreten.");
          });
      }
    }
  }
};
</script>

<style scoped></style>
