<template>
  <v-tooltip top :disabled="inquiryItem.currentUploadRequest.state !== null">
    <template v-slot:activator="{ on: tooltip }">
      <div v-on="{ ...tooltip }">
        <v-dialog max-width="600px" v-model="dialog" persistent>
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="{ ...dialog }"
              :disabled="inquiryItem.currentUploadRequest.state === null"
            >
              Unterlagen erneut anfordern
              <v-icon right dark>mdi-autorenew</v-icon>
            </v-btn>
          </template>
          <ui-dialog-card title="Unterlagen erneut anfordern">
            <template v-slot:text>
              <v-form @submit.prevent="submit" v-model="isValid" ref="form">
                <v-textarea
                  label="Hinweis für Kunden"
                  filled
                  v-model="help"
                  placeholder="Teilen Sie dem Kunden mit, warum Sie die Unterlagen erneut einfordern."
                  :rules="[rules.required, rules.long_text]"
                  :counter="6000"
                />
              </v-form>
            </template>

            <template v-slot:actions>
              <v-spacer />
              <v-btn text @click="close" class="mr-2">
                Abbrechen
              </v-btn>
              <v-btn
                color="primary"
                @click="submit"
                :disabled="!isValid"
                :loading="loading"
              >
                Unterlagen erneut anfordern
                <v-icon right dark>mdi-autorenew</v-icon>
              </v-btn>
            </template>
          </ui-dialog-card>
        </v-dialog>
      </div>
    </template>
    <span>
      Sie können die Unterlage nicht erneut anfordern, weil der vorherige Aufruf
      noch nicht geprüft wurde.
    </span>
  </v-tooltip>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    itemProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    item: {},
    help: "",

    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    inquiryItem() {
      return this.$store.getters["inquiryItem/inquiryItem"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    itemProp(val) {
      this.section = val;
    }
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      showInquiryItem: "inquiryItem/show"
    }),
    requestAgain() {
      this.axios
        .post(
          "/upload-requests/request-again/" +
            this.inquiryItem.currentUploadRequest.id,
          {
            help: this.help
          }
        )
        .then(() => {
          this.$snotify.success(
            "Die Unterlagen wurden erfolgreich erneut angefordert."
          );
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },
    // functional
    close() {
      this.showInquiryItem({ id: this.$route.params.id });
      this.dialog = false;
      this.loading = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.requestAgain();
    }
  }
};
</script>

<style scoped></style>
