import Vue from "vue";
import VueRouter from "vue-router";
import NoAuthWrapper from "../views/noauth/Wrapper";
import PassThrough from "@/views/auth/views/PassThrough";
import PassThroughMain from "@/views/auth/views/PassThroughMain";
import Login from "@/views/noauth/Login";
import ForgotPassword from "@/views/noauth/ForgotPassword";

import Dashboard from "@/views/auth/views/Dashboard";

import Middleware from "../middleware/index";
import Home from "@/views/noauth/Home";
import ResetPassword from "@/views/noauth/ResetPassword";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: NoAuthWrapper,
    children: [
      {
        path: "",
        name: "Home",
        components: {
          main: Home
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest]
        }
      },
      {
        path: "/login",
        name: "Login",
        components: {
          main: Login
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest]
        }
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        components: {
          main: ForgotPassword,
          middleware: [Middleware.guest]
        },
        meta: {
          nav: false
        }
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        components: {
          main: ResetPassword,
          middleware: [Middleware.guest]
        },
        meta: {
          nav: false
        }
      }
    ]
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "",
    name: "AuthWrapper",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/auth/Wrapper"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: {
          main: Dashboard
        },
        meta: {
          barTitle: "Dashboard",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/profile/:id",
        name: "UserProfile",
        components: {
          main: () =>
            import(/* webpackChunkName: "auth" */ "../views/auth/views/Profile")
        },
        meta: {
          barTitle: "Profil",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/inquiries",
        name: "Inquiries",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/Index"
            )
        },
        meta: {
          barTitle: "Anfragen",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/inquiries/column",
        name: "InquiriesColumn",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/Column"
            )
        },
        meta: {
          barTitle: "Anfragen: Spaltenansicht",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/inquiries/form",
        name: "InquiriesForm",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/Form"
            )
        },
        meta: {
          barTitle: "Anfrage aufnehmen",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/inquiries/form/:id",
        name: "InquiriesEdit",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/Form"
            )
        },
        meta: {
          barTitle: "Anfrage bearbeiten",
          middleware: [Middleware.auth]
        },
        children: [
          {
            path: "general",
            name: "InquiriesEditGeneral",
            component: () =>
              import(
                /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/Form/General"
              ),
            meta: {
              barTitle: "Allgemein - Anfragen bearbeiten",
              middleware: [Middleware.auth],
              permissions: ["consultant permission"]
            }
          },
          {
            path: "layout",
            name: "InquiriesEditLayout",
            component: () =>
              import(
                /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/Form/Layout"
              ),
            meta: {
              barTitle: "Layout - Anfragen bearbeiten",
              middleware: [Middleware.auth],
              permissions: ["consultant permission"]
            }
          },
          {
            path: "preview",
            name: "InquiriesEditPreview",
            component: () =>
              import(
                /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/Form/Preview"
              ),
            meta: {
              barTitle: "Vorschau - Anfrage bearbeiten",
              middleware: [Middleware.auth],
              permissions: ["consultant permission"]
            }
          }
        ]
      },
      {
        path: "/inquiries/list",
        name: "InquiriesList",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/List"
            )
        },
        meta: {
          barTitle: "Anfragen: Listenansicht",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/inquiries/show/:id",
        name: "InquiriesShow",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/Show"
            )
        },
        meta: {
          barTitle: "Anfragen anzeigen",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/inquiries/settings/:id",
        name: "InquiriesSettings",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/inquiries/Settings"
            )
        },
        meta: {
          barTitle: "Anfragen Einstellungen",
          middleware: [Middleware.auth],
          permissionsAny: [
            "consultant permission",
            "technical-admin permission"
          ]
        }
      },
      {
        path: "/inquiry-items/show/:id",
        name: "InquiryItemsShow",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/inquiry-items/Show"
            )
        },
        meta: {
          barTitle: "Anforderung anzeigen",
          middleware: [Middleware.auth]
        }
      },
      {
        path: "/settings",
        name: "Settings",
        components: {
          main: PassThrough
        },
        children: [
          {
            path: "customer-types",
            component: PassThroughMain,
            meta: {
              middleware: [Middleware.auth],
              permissions: ["technical-admin permission"]
            },
            children: [
              {
                path: "",
                name: "SettingsCustomerTypes",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/CustomerTypes/Index"
                    )
                },
                meta: {
                  barTitle: "Kundentypen",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              },
              {
                path: "form",
                name: "SettingsCustomerTypesCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/CustomerTypes/Form"
                    )
                },
                meta: {
                  barTitle: "Kundentypen erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsCustomerTypesEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/CustomerTypes/Form"
                    )
                },
                meta: {
                  barTitle: "Kundentypen bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              }
            ]
          },
          {
            path: "documents",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsDocuments",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Documents/Index"
                    )
                },
                meta: {
                  barTitle: "Dokumente",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form",
                name: "SettingsDocumentsCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Documents/Form"
                    )
                },
                meta: {
                  barTitle: "Dokument erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsDocumentsEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Documents/Form"
                    )
                },
                meta: {
                  barTitle: "Dokument bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              }
            ]
          },
          {
            path: "document-types",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsDocumentTypes",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/DocumentTypes/Index"
                    )
                },
                meta: {
                  barTitle: "Dokumententypen",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form",
                name: "SettingsDocumentTypesCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/DocumentTypes/Form"
                    )
                },
                meta: {
                  barTitle: "Dokumententypen erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsDocumentTypesEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/DocumentTypes/Form"
                    )
                },
                meta: {
                  barTitle: "Dokumententypen bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              }
            ]
          },
          {
            path: "email-configs",
            children: [
              {
                path: "",
                name: "SettingsEmailConfigs",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/EmailConfigs/Index"
                    )
                },
                meta: {
                  barTitle: "E-Mails",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              },
              {
                path: "/form/:id",
                name: "SettingsEmailConfigsEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/EmailConfigs/Form"
                    )
                },
                meta: {
                  barTitle: "E-Mail konfigurieren",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              }
            ]
          },
          {
            path: "forms",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsForms",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Forms/Index"
                    )
                },
                meta: {
                  barTitle: "Formulare",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form",
                name: "SettingsFormsCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Forms/Form"
                    )
                },
                meta: {
                  barTitle: "Formular erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsFormsEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Forms/Form"
                    )
                },
                meta: {
                  barTitle: "Formular bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              }
            ]
          },
          {
            path: "inquiries",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsInquiries",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Inquiries/Index"
                    )
                },
                meta: {
                  barTitle: "Anfragenvorlagen",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form",
                name: "SettingsInquiriesCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Inquiries/Form"
                    )
                },
                meta: {
                  barTitle: "Anfragenvorlage erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsInquiriesEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Inquiries/Form"
                    )
                },
                meta: {
                  barTitle: "Anfragenvorlage bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                },
                children: [
                  {
                    path: "general",
                    name: "SettingsInquiriesEditGeneral",
                    component: () =>
                      import(
                        /* webpackChunkName: "auth" */ "../views/auth/views/settings/Inquiries/Form/General"
                      ),
                    meta: {
                      barTitle: "Allgemein - Anfragenvorlage bearbeiten",
                      middleware: [Middleware.auth],
                      permissions: ["subject-admin permission"]
                    }
                  },
                  {
                    path: "layout",
                    name: "SettingsInquiriesEditLayout",
                    component: () =>
                      import(
                        /* webpackChunkName: "auth" */ "../views/auth/views/settings/Inquiries/Form/Layout"
                      ),
                    meta: {
                      barTitle: "Layout - Anfragenvorlage bearbeiten",
                      middleware: [Middleware.auth],
                      permissions: ["subject-admin permission"]
                    }
                  },
                  {
                    path: "preview",
                    name: "SettingsInquiriesEditPreview",
                    component: () =>
                      import(
                        /* webpackChunkName: "auth" */ "../views/auth/views/settings/Inquiries/Form/Preview"
                      ),
                    meta: {
                      barTitle: "Vorschau - Anfragenvorlage bearbeiten",
                      middleware: [Middleware.auth],
                      permissions: ["subject-admin permission"]
                    }
                  }
                ]
              }
            ]
          },
          {
            path: "inquiries-statistics",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsInquiriesStatistics",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/InquiriesStatistics/Index"
                    )
                },
                meta: {
                  barTitle: "Anfragen - Statistiken",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              }
            ]
          },
          {
            path: "instance-settings",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsInstanceSettings",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/InstanceSettings/Index"
                    )
                },
                meta: {
                  barTitle: "Systemeinstellungen",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              }
            ]
          },
          {
            path: "products",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsProducts",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Products/Index"
                    )
                },
                meta: {
                  barTitle: "Produkte",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form",
                name: "SettingsProductsCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Products/Form"
                    )
                },
                meta: {
                  barTitle: "Produkt erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsProductsEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Products/Form"
                    )
                },
                meta: {
                  barTitle: "Produkt bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              }
            ]
          },
          {
            path: "teams",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsTeams",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Teams/Index"
                    )
                },
                meta: {
                  barTitle: "Teams",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              },
              {
                path: "form",
                name: "SettingsTeamsCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Teams/Form"
                    )
                },
                meta: {
                  barTitle: "Team erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsTeamsEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Teams/Form"
                    )
                },
                meta: {
                  barTitle: "Team bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              }
            ]
          },
          {
            path: "upload-types",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsUploadTypes",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/UploadTypes/Index"
                    )
                },
                meta: {
                  barTitle: "Dateiformate",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form",
                name: "SettingsUploadTypesCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/UploadTypes/Form"
                    )
                },
                meta: {
                  barTitle: "Dateiformat erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsUploadTypesEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/UploadTypes/Form"
                    )
                },
                meta: {
                  barTitle: "Dateiformat bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["subject-admin permission"]
                }
              }
            ]
          },
          {
            path: "users",
            component: PassThroughMain,
            children: [
              {
                path: "",
                name: "SettingsUsers",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Users/Index"
                    )
                },
                meta: {
                  barTitle: "Nutzer",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              },
              {
                path: "form",
                name: "SettingsUsersCreate",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Users/Form"
                    )
                },
                meta: {
                  barTitle: "Nutzer erstellen",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              },
              {
                path: "form/:id",
                name: "SettingsUsersEdit",
                components: {
                  main: () =>
                    import(
                      /* webpackChunkName: "auth" */ "../views/auth/views/settings/Users/Form"
                    )
                },
                meta: {
                  barTitle: "Nutzer bearbeiten",
                  middleware: [Middleware.auth],
                  permissions: ["technical-admin permission"]
                }
              }
            ]
          }
        ]
      },
      {
        path: "/shares/:id",
        name: "SharesShow",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/shares/Show"
            )
        },
        meta: {
          barTitle: "Geteiltes Objekt",
          middleware: [Middleware.auth],
          permissions: ["consultant permission"]
        }
      },
      {
        path: "/teams",
        name: "Teams",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/teams/Index"
            )
        },
        meta: {
          barTitle: "Ihre Teams",
          middleware: [Middleware.auth],
          permissions: ["consultant permission"]
        }
      },
      {
        path: "/teams/:id",
        name: "TeamsShow",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/teams/Show"
            )
        },
        meta: {
          barTitle: "Ansicht Team",
          middleware: [Middleware.auth],
          permissions: ["consultant permission"]
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

function nextCheck(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMidd = nextCheck(context, middleware, index + 1);

    nextMiddleware({ ...context, next: nextMidd });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const ctx = {
      from,
      next,
      router,
      to
    };

    const nextMiddleware = nextCheck(ctx, middleware, 1);

    return middleware[0]({ ...ctx, next: nextMiddleware });
  }

  return next();
});

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + " • fileCQ"
      : to.meta.barTitle
      ? to.meta.barTitle + " • fileCQ"
      : "fileCQ";
  });
});

export default router;
