<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <div class="text-overline">
          Vollständig
        </div>
        <div
          v-if="inquiryDashboardIndex.assignedInquiries_fulfillment.length < 1"
        >
          Keine Anfragen vorhanden.
        </div>
        <div
          v-for="inquiry in inquiryDashboardIndex.assignedInquiries_fulfillment"
          :key="inquiry.id"
          class="mt-4"
        >
          <util-inquiry-card
            :inquiryProp="inquiry"
            sortBy="fulfillment_ratio"
          />
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-overline">
          Neue Dokumente
        </div>
        <div v-if="inquiryDashboardIndex.assignedInquiries_latest.length < 1">
          Keine Anfragen vorhanden.
        </div>
        <div
          v-for="inquiry in inquiryDashboardIndex.assignedInquiries_latest"
          :key="inquiry.id"
          class="mt-4"
        >
          <util-inquiry-card
            :inquiryProp="inquiry"
            sortBy="last_customer_action_at"
          />
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-overline">
          Überfällig
        </div>
        <div v-if="inquiryDashboardIndex.assignedInquiries_oldest.length < 1">
          Keine Anfragen vorhanden.
        </div>
        <div
          v-for="inquiry in inquiryDashboardIndex.assignedInquiries_oldest"
          :key="inquiry.id"
          class="mt-4"
        >
          <util-inquiry-card
            :inquiryProp="inquiry"
            sortBy="last_customer_action_at"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {
    inquiryDashboardIndex() {
      return this.$store.getters["inquiryDashboard/index"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
