import axios from "axios";

export default {
  state(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/state")
        .then(response => {
          ctx.commit("setSettings", response.data.settings);
          ctx.commit("setBrandUrl", response.data.brand_url);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  userState(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user-state")
        .then(response => {
          ctx.commit("setSettings", response.data.settings);
          ctx.commit("setBrandUrl", response.data.brand_url);
          ctx.commit("setLoggedIn", true);
          ctx.commit("setPermissions", response.data.permissions);
          ctx.commit("setRoles", response.data.roles);
          ctx.commit("setUserDetails", response.data.user);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  login(ctx, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/login", payload)
        .then(response => {
          if (response.data.access_token) {
            localStorage.setItem("token", response.data.access_token);
            ctx.commit("setLoggedIn", true);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  logout(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/auth/logout")
        .then(response => {
          localStorage.removeItem("token");
          ctx.commit("setLoggedIn", false);
          resolve(response.data);
        })
        .catch(error => {
          localStorage.removeItem("token");
          ctx.commit("setLoggedIn", false);
          reject(error);
        });
    });
  }
};
