<template>
  <div style="width: 100%;" class="mb-4">
    <v-row align="end">
      <v-col cols="12" xl="8" lg="8" md="8">
        <div class="text-h3 mb-4">
          {{ inquiry.name }}
        </div>
        <div
          class="body-1 mb-4"
          v-text="inquiry.description"
          v-if="inquiry.description"
        />
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" class="pa-8">
        <!--        <img
          src="@/assets/inquiries/filling.svg"
          alt="Anfrage"
          style="height:100%;"
        />-->
      </v-col>
    </v-row>
    <!--    <v-subheader class="mb-4">
      {{ inquiry.created_at }}
    </v-subheader>-->
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {
    inquiry() {
      return this.$store.getters["currentInquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
