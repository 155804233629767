import axios from "axios";

export default {
  store(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/inquiries", {
          inquiry: payload
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  patch(ctx, { id, payload, onlySections, finish }) {
    const urlAppend = onlySections
      ? "?onlySections=true"
      : finish
      ? "?finish=true"
      : "";
    return new Promise((resolve, reject) => {
      axios
        .patch("/inquiries/" + id + urlAppend, {
          inquiry: payload
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  destroy(ctx, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/inquiries/" + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
