<template>
  <v-data-table
    :items-per-page="-1"
    :items="inquiryItemsProp"
    :headers="headers"
    hide-default-footer
  >
    <template v-slot:item.state="{}">
      <div style="pointer-events: none;">
        <v-btn outlined small>
          <v-icon left x-small>mdi-brightness-1</v-icon>
          Test
        </v-btn>
      </div>
    </template>
    <template v-slot:item.action="{}">
      <div style="pointer-events: none;">
        <v-btn outlined small>
          <v-icon left x-small>mdi-brightness-1</v-icon>
          Test
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    inquiryItemsProp: Object
  },
  data: () => ({
    headers: [
      { text: "Status", align: "start", value: "state" },
      { text: "Name", align: "start", value: "name" },
      { text: "", align: "end", value: "action" }
    ]
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
