import axios from "axios";

export default {
  index(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/dashboard/inquiries")
        .then(response => {
          ctx.commit("setIndex", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
