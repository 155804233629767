<template>
  <div>
    <v-card v-if="user">
      <v-card-title>
        <v-row align="center">
          <v-col cols="12" md="auto">
            <v-avatar class=" white--text" color="primary">
              <span v-if="!user.image_url">{{ user.initials }}</span>
              <img v-else :src="user.image_url" alt="Profilbild" />
            </v-avatar>
          </v-col>
          <v-col cols="12" md="auto" class="body-1">
            <div>
              {{ user.title }} {{ user.first_name }}
              {{ user.last_name }}
            </div>
            <div v-if="user.label" class="text-caption">
              Kd Nr.: {{ user.label }}
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-list two-line class="body-1">
          <v-list-item :href="'mailto:' + user.email">
            <v-list-item-icon>
              <v-icon>
                mdi-email
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>E-Mail-Adresse</v-list-item-title>
              <v-list-item-subtitle>
                {{ user.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="'tel:' + user.phone1">
            <v-list-item-icon>
              <v-icon>
                mdi-phone
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Telefon</v-list-item-title>
              <v-list-item-subtitle>
                {{ user.phone1 ? user.phone1 : "Keine Angabe" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="'tel:' + user.phone2">
            <v-list-item-icon>
              <v-icon>
                mdi-phone
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Telefon 2</v-list-item-title>
              <v-list-item-subtitle>
                {{ user.phone2 ? user.phone2 : "Keine Angabe" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-map-marker
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Straße und Hausnummer</v-list-item-title>
              <v-list-item-subtitle>
                {{ user.address1 }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon> </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Adresszusatz</v-list-item-title>
              <v-list-item-subtitle>
                {{ user.address2 ? user.address2 : "Keine Angabe" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon> </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>PLZ und Ort</v-list-item-title>
              <v-list-item-subtitle>
                {{ user.zip }} {{ user.city }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
