<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :loading="loading"
    multi-sort
    no-data-text="Es liegen noch keine Daten vor."
    no-results-text="Es wurden keine Ergebnisse gefunden."
    loading-text="Lädt..."
    :footer-props="{
      itemsPerPageText: 'Einträge pro Seite',
      itemsPerPageOptions: [10, 25, 50, 100, -1],
      itemsPerPageAllText: 'Alle'
    }"
  >
    <template v-slot:item.logo="{ item }">
      <v-img :src="item.logo_url" height="90px" contain max-width="100px" />
    </template>
    <template v-slot:item.color="{ item }">
      <v-avatar :color="item.color" size="25" />
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }" v-if="editRoute">
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            icon
            :to="{ name: editRoute, params: { id: item.id } }"
            exact
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>Ansicht</span>
      </v-tooltip>
      <!--      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }" v-if="editRoute">
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            icon
            target="_blank"
            :to="{ name: editRoute, params: { id: item.id } }"
            exact
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>In neuem Tab öffnen</span>
      </v-tooltip>-->
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | moment("DD.MM.YYYY") }}
      <!--      {{ item.created_at | moment("DD.MM.YYYY HH:mm") }}-->
    </template>

    <template v-slot:item.settings="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }" v-if="settingsRoute">
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            icon
            :to="{
              name: settingsRoute,
              params: { id: item.id },
              query: { settings: true }
            }"
            exact
          >
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <span>Einstellungen</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: [
    "headers",
    "search",
    "items",
    "type",
    "loading",
    "editRoute",
    "settingsRoute"
  ]
};
</script>

<style scoped></style>
