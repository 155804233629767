<template>
  <div class="mt-4">
    <div class="grey--text mb-4">
      Hinweis: Der hier angezeigte Status einer Anfrage stellt lediglich den
      internen Projektstatus dar, jedoch nicht den Status der Anfrage selbst.
    </div>
    <v-slide-y-transition>
      <v-row
        v-if="
          inquiryDashboardIndex.assignedInquiries_open &&
            inquiryDashboardIndex.assignedInquiries_in_work &&
            inquiryDashboardIndex.assignedInquiries_finished
        "
      >
        <v-col cols="12" md="4">
          <v-card flat color="grey lighten-3" min-height="400px" height="100%">
            <v-card-title class="text-overline">
              <v-icon left>mdi-radiobox-marked</v-icon>
              Offen ({{ inquiryDashboardIndex.assignedInquiries_open.length }})
              <v-spacer />
            </v-card-title>
            <v-card-text class="mt-n4">
              <draggable
                :list="inquiryDashboardIndex.assignedInquiries_open"
                group="inquiries"
                style="min-height: 200px"
                @end="updateInquiryStates"
              >
                <div
                  v-for="inquiry in inquiryDashboardIndex.assignedInquiries_open"
                  :key="inquiry.id"
                  class="mt-4"
                >
                  <util-inquiry-card :inquiryProp="inquiry" :sortBy="sortBy" />
                </div>
              </draggable>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat color="grey lighten-3" min-height="400px" height="100%">
            <v-card-title class="text-overline">
              <v-icon left>mdi-briefcase-outline</v-icon>
              In Arbeit ({{
                inquiryDashboardIndex.assignedInquiries_in_work.length
              }})
            </v-card-title>
            <v-card-text class="mt-n4" style="min-height: 100%;">
              <draggable
                :list="inquiryDashboardIndex.assignedInquiries_in_work"
                group="inquiries"
                style="min-height: 200px;"
                @end="updateInquiryStates"
              >
                <div
                  v-for="inquiry in inquiryDashboardIndex.assignedInquiries_in_work"
                  :key="inquiry.id"
                  class="mt-4"
                >
                  <util-inquiry-card :inquiryProp="inquiry" :sortBy="sortBy" />
                </div>
              </draggable>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat color="grey lighten-3" min-height="400px" height="100%">
            <v-card-title class="text-overline">
              <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
              Fertig ({{
                inquiryDashboardIndex.assignedInquiries_finished.length
              }})
            </v-card-title>
            <v-card-text class="mt-n4" style="min-height: 100%;">
              <draggable
                :list="inquiryDashboardIndex.assignedInquiries_finished"
                group="inquiries"
                style="min-height: 200px"
                @end="updateInquiryStates"
              >
                <div
                  v-for="inquiry in inquiryDashboardIndex.assignedInquiries_finished"
                  :key="inquiry.id"
                  class="mt-4"
                >
                  <util-inquiry-card :inquiryProp="inquiry" :sortBy="sortBy" />
                </div>
              </draggable>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: { sortDesc: Boolean, sortBy: String, search: String },
  components: {
    draggable
  },
  watch: {},
  data: () => ({
    loading: false,
    isValid: false,
    assignedInquiries_open: [],
    assignedInquiries_in_work: [],
    assignedInquiries_finished: []
  }),
  computed: {
    inquiryDashboardIndex() {
      return this.$store.getters["inquiryDashboard/index"];
    },
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  created() {},
  methods: {
    updateInquiryStates() {
      this.axios
        .patch("/inquiries/state", {
          open: this.inquiryDashboardIndex.assignedInquiries_open,
          in_work: this.inquiryDashboardIndex.assignedInquiries_in_work,
          finished: this.inquiryDashboardIndex.assignedInquiries_finished
        })
        .catch(e => {
          console.log(e);
        });
    },

    consoleTest() {
      console.log("test");
    }
  }
};
</script>

<style scoped></style>
