<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed v-bind="attrs" v-on="on">
        Zugriffsberechtigungen verwalten
        <v-icon right dark>mdi-share-variant-outline</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Zugriffsberechtigungen verwalten">
      <template v-slot:text>
        <v-form @submit.prevent="submit" v-model="isValid" ref="form">
          <div class="text-overline mb-4">
            Zugriffsberechtigung hinzufügen
          </div>

          <v-slide-y-transition group>
            <v-select
              key="selectAccessorType"
              v-model="share.accessor_type"
              :items="[
                { value: 'user', text: 'Berater' },
                { value: 'team', text: 'Team' }
              ]"
              filled
              label="Zugreifertyp"
              :rules="[rules.required]"
              required
            />

            <v-select
              key="selectAccessorUser"
              v-if="share.accessor_type === 'user'"
              v-model="share.accessor_id"
              :items="users"
              filled
              label="Berater"
              :rules="[rules.required]"
              required
              item-text="name"
              item-value="id"
            />

            <v-select
              key="selectAccessorTeam"
              v-if="share.accessor_type === 'team'"
              v-model="share.accessor_id"
              :items="teams"
              filled
              label="Team"
              :rules="[rules.required]"
              required
              item-text="name"
              item-value="id"
            />

            <v-textarea
              key="selectAccessorHint"
              v-if="share.accessor_type && share.accessor_id"
              filled
              v-model="share.hint"
              label="Hinweis für die Bearbeiter"
            />

            <div
              class="d-flex justify-end"
              v-if="share.accessor_type && share.accessor_id"
              key="accessorButtons"
            >
              <v-btn
                text
                @click="
                  share.accessor_type = '';
                  share.accessor_id = '';
                  share.hint = '';
                  $refs.form.resetValidation();
                "
              >
                Abbrechen
              </v-btn>
              <v-btn
                color="primary"
                class="ml-2"
                @click="storeShare"
                :loading="loading"
                :disabled="!isValid"
              >
                Hinzufügen
                <v-icon right dark>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-slide-y-transition>
        </v-form>

        <div class="text-overline mb-4">
          Erteilte Zugriffsberechtigungen ({{ inquiryItem.shares.length }})
        </div>
        <div v-if="inquiryItem.shares.length < 1" class="body-1">
          Es wurden bisher keine Zugriffe erteilt.
        </div>
        <div v-else>
          <v-list-item
            v-for="share in inquiryItem.shares"
            :key="share.id"
            class="px-0"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ share.created_at | moment("DD.MM.YYYY HH:mm") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Erteilt an
                {{
                  share.accessor_type === "App\\Models\\Team"
                    ? "Team: "
                    : "Berater: "
                }}
                {{ share.accessor.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                depressed
                :to="{ name: 'SharesShow', params: { id: share.id } }"
              >
                Ansehen
                <v-icon right>mdi-eye-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn color="primary" block @click="close">
          Okay
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    share: {
      accessor_type: "",
      accessor_id: "",
      hint: ""
    },
    teams: [],
    users: [],

    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    inquiryItem() {
      return this.$store.getters["inquiryItem/inquiryItem"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    itemProp(val) {
      this.section = val;
    }
  },
  created() {
    this.indexTeams();
    this.indexUsers();
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      showInquiryItem: "inquiryItem/show"
    }),

    storeShare() {
      this.loading = true;
      let item = this.share;
      item.shareable_type = "App\\Models\\InquiryItem";
      item.shareable_id = this.$route.params.id;
      item.accessor_rights = "write";
      this.axios
        .post("/shares", {
          share: item
        })
        .then(() => {
          this.$snotify.success(
            "Die Zugriffsberechtigung wurde erfolgreich erteilt."
          );
          this.share = {};
          this.$refs.form.resetValidation();
          this.showInquiryItem({ id: this.$route.params.id });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Erteilen der Zugriffsberechtigung ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.share.accessor_type = "";
      this.share.accessor_id = "";
      this.share.hint = "";
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
    },

    indexTeams() {
      this.axios
        .get("/teams")
        .then(res => {
          this.teams = res.data.teams;
          this.teams_archived = res.data.teams_archived;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },

    indexUsers() {
      this.axios
        .get("/users")
        .then(r => {
          this.users = r.data.users_intern;
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style scoped></style>
