<template>
  <div>
    <v-autocomplete
      v-if="!isTemplate"
      filled
      label="Vorlage"
      v-model="inquiry.template_id"
      :items="inquiryTemplates"
      item-text="name"
      item-value="id"
      @input="changeInquiryTemplate()"
      :loading="loadingTemplate"
      clearable
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <v-list-item-subtitle v-if="data.item.description">
            {{ data.item.description }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="!data.item.is_extendable"
            class="font-weight-bold"
          >
            Nicht erweiterbar
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <Draggable
      v-model="inquiry.inquirySections"
      group="sections"
      handle=".handle"
      @end="patchInquiry"
    >
      <template v-for="section in inquiry.inquirySections">
        <inquiries-wizard-layout-section
          :sectionProp="section"
          :key="section.id"
          :is-template="isTemplate"
        />
      </template>
    </Draggable>
    <v-card-actions
      class="mt-8 pa-0"
      v-if="!inquiry.template_id || inquiry.is_extendable"
    >
      <inquiries-wizard-layout-section-dialog :is-template="isTemplate" />
    </v-card-actions>
    <v-alert type="warning" dense text v-else>
      Diese Vorlage ist nicht erweiter- und editierbar.
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    inquiryTemplates: [],
    // functional
    isValid: false,
    loadingTemplate: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["currentInquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {
    this.indexInquiryTemplates();
  },
  methods: {
    patchInquiry() {
      this.$store
        .dispatch("inquiry/patch", {
          id: this.$route.params.id,
          payload: this.inquiry,
          onlySections: true
        })
        .catch(e => {
          console.log(e);
        });
    },

    // functional
    indexInquiryTemplates() {
      this.axios
        .get("/inquiries?is_template=true")
        .then(res => {
          this.inquiryTemplates = res.data.inquiries;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },

    changeInquiryTemplate() {
      this.loadingTemplate = true;
      this.axios
        .patch(
          "/inquiries/c-t/" + this.inquiry.id + "/" + this.inquiry.template_id
        )
        .then(r => {
          this.inquiry.inquirySections = r.data.inquirySections;
          this.inquiry.is_extendable = r.data.is_extendable;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loadingTemplate = false;
        });
    }
  }
};
</script>

<style scoped></style>
