var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.uploadRequest)?_c('v-card',[_c('v-list-item',{staticClass:"primary",attrs:{"dark":"","two-line":""}},[_c('v-list-item-action',[_c('v-menu',{attrs:{"top":"","offset-y":"","offset-x":"","open-on-hover":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"dark":"","size":"35","color":_vm.statusColor}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(_vm._s(_vm.statusIcon))])],1)]}}],null,false,1498775188)},[_c('v-card',{attrs:{"max-width":"500px"}},[_c('v-card-actions',{staticClass:"pb-0"},[_c('v-btn',{staticStyle:{"pointer-events":"none"},attrs:{"text":"","color":_vm.statusColor}},[_vm._v(" "+_vm._s(_vm.statusTitle)+" "),_c('v-icon',{attrs:{"right":"","color":_vm.statusColor}},[_vm._v(" "+_vm._s(_vm.statusIcon)+" ")])],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_vm._v(" "+_vm._s(_vm.statusText)+" ")])],1)],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" Aufruf vom "+_vm._s(_vm._f("moment")(_vm.uploadRequest.created_at,"DD.MM.YYYY HH:mm"))+" ")]),(_vm.uploadRequest.help)?_c('v-list-item-subtitle',{staticClass:"font-weight-medium body-1"},[_vm._v(" Hinweis: "+_vm._s(_vm.uploadRequest.help)+" ")]):_vm._e()],1)],1),_c('ui-secondary-divider'),(
        _vm.$hasPermissions('consultant permission') ||
          (_vm.uploadRequest.uploadsIntern &&
            _vm.uploadRequest.uploadsIntern.length > 0)
      )?_c('div',[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$hasPermissions("consultant permission") ? "Intern bereitgestellt" : "Bereitgestellte Unterlagen Ihrer Bank")+" ")]),_c('v-divider'),(
          _vm.uploadRequest.uploadsIntern &&
            _vm.uploadRequest.uploadsIntern.length > 0
        )?_c('div',[_vm._l((_vm.uploadRequest.uploadsIntern),function(upload){return [_c('inquiry-items-show-upload-requests-upload',{key:upload.id,attrs:{"upload-prop":upload,"upload-request-state":_vm.uploadRequest.state}})]})],2):_c('v-card-text',[_vm._v(" Es wurde nichts hochgeladen / freigegeben. ")])],1):_vm._e(),_c('div',[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$hasPermissions("consultant permission") ? "Durch Kunden bereitgestellt" : "Ihre hochgeladenen Unterlagen")+" ")]),_c('v-divider'),(
          _vm.uploadRequest.uploadsExtern &&
            _vm.uploadRequest.uploadsExtern.length > 0
        )?_c('div',[_vm._l((_vm.uploadRequest.uploadsExtern),function(upload){return [_c('inquiry-items-show-upload-requests-upload',{key:upload.id,attrs:{"upload-prop":upload,"upload-request-state":_vm.uploadRequest.state}})]})],2):_c('v-card-text',[(_vm.$hasPermissions('consultant permission'))?_c('span',[_vm._v("Es wurde nichts hochgeladen / freigegeben. ")]):(_vm.uploadRequest.state === null)?_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","text":"","type":"warning"}},[_vm._v(" Sie haben noch keine Unterlagen eingereicht. ")]):_vm._e()],1)],1),(!_vm.isShare)?_c('v-card-actions',{staticClass:"pa-4"},[_c('div',{staticStyle:{"width":"100%"}},[(_vm.$hasPermissions('consultant permission'))?_c('div',[(_vm.uploadRequest.state === null)?_c('ui-upload-dialog'):_vm._e(),(_vm.uploadRequest.state === null)?_c('div',{staticClass:"mt-1"},[_c('inquiry-items-show-upload-requests-check-dialog',{attrs:{"is-text":""}})],1):_c('div',[_c('inquiry-items-show-upload-requests-change-state-dialog'),_c('inquiry-items-show-upload-requests-download-all',{staticClass:"mt-1",attrs:{"upload-request":_vm.uploadRequest}})],1)],1):(_vm.$hasPermissions('customer permission'))?_c('div',[(
              _vm.uploadRequest.state === null &&
                _vm.uploadRequest.uploadsExtern.length < 1
            )?_c('ui-upload-dialog'):(
              _vm.uploadRequest.state === null &&
                _vm.uploadRequest.uploadsExtern.length > 0
            )?_c('div',[_c('inquiry-items-show-upload-requests-check-dialog'),_c('div',{staticClass:"mt-1"},[_c('ui-upload-dialog',{attrs:{"is-text":""}})],1)],1):_vm._e()],1):_vm._e()])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }