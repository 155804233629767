var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"11"}},[(!_vm.sectionProp.release_state)?_c('v-chip',{staticClass:"mb-4",attrs:{"label":"","small":"","color":"grey lighten-3"}},[_vm._v(" Noch nicht freigegeben ")]):_vm._e(),(_vm.sectionProp.name)?_c('div',{staticClass:"text-h5 mb-4",domProps:{"innerHTML":_vm._s(_vm.sectionProp.name)}}):_vm._e(),(_vm.sectionProp.description)?_c('div',{staticClass:"body-1 mb-4",domProps:{"innerHTML":_vm._s(_vm.sectionProp.description)}}):_vm._e()],1),(
        (_vm.$hasPermissions('consultant permission') &&
          !_vm.isPreview &&
          !_vm.isShare) ||
          (_vm.isShare && _vm.sharePermission === 'write')
      )?_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","disabled":_vm.sectionProp.release_state !== null}},'v-list-item',attrs,false),on),[_vm._v(" Freigeben ohne E-Mail ")])]}}],null,false,1380490304),model:{value:(_vm.sectionProp.releaseDialog1),callback:function ($$v) {_vm.$set(_vm.sectionProp, "releaseDialog1", $$v)},expression:"sectionProp.releaseDialog1"}},[_c('v-card',[_c('inquiries-show-timeline-section-release',{attrs:{"isWithMail":false}}),_c('v-card-text',{staticClass:"d-flex justify-end mt-4"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.sectionProp.releaseDialog1 = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.sectionProp.releaseDialog1 = false;
                    _vm.release('released');}}},[_vm._v(" Freigeben ohne E-Mail "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-lock-open-outline ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","disabled":_vm.sectionProp.release_state !== null}},'v-list-item',attrs,false),on),[_vm._v(" Freigeben mit E-Mail ")])]}}],null,false,2753416380),model:{value:(_vm.sectionProp.releaseDialog),callback:function ($$v) {_vm.$set(_vm.sectionProp, "releaseDialog", $$v)},expression:"sectionProp.releaseDialog"}},[_c('v-card',[_c('inquiries-show-timeline-section-release',{attrs:{"isWithMail":true}}),_c('v-card-text',{staticClass:"d-flex justify-end mt-4"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.sectionProp.releaseDialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.sectionProp.releaseDialog = false;
                    _vm.release('released_with_email');}}},[_vm._v(" Freigeben mit E-Mail "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-lock-open-outline ")])],1)],1)],1)],1),_c('inquiries-wizard-layout-document-dialog',{attrs:{"section-id":_vm.sectionProp.id,"is-wizard":false}})],1)],1)],1):_vm._e()],1),(
      _vm.$hasPermissions('consultant permission') ||
        _vm.sectionProp.release_state !== null
    )?_c('div',[_c('v-card',[_c('v-list',[_vm._l((_vm.sectionProp.children),function(child){return [_c('inquiries-show-timeline-list-item',{key:child.id,attrs:{"inquiryItemProp":child,"is-preview":_vm.isPreview}})]})],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }