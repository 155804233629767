import axios from "axios";

export default {
  show(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/inquiry-items/" + id)
        .then(response => {
          ctx.commit("setInquiryItem", response.data.inquiryItem);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  patch(ctx, { id, payload, onlyChildren }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          "/inquiry-items/" + id + (onlyChildren ? "?onlyChildren=true" : ""),
          {
            inquiryItem: payload
          }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  destroy(ctx, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/inquiry-items/" + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // functional

  statusIcon({ inquiryItem }) {
    if (inquiryItem.attachable_type === "App\\Models\\Document") {
      switch (inquiryItem.current_state) {
        case null:
          return "mdi-checkbox-blank-circle-outline";
        case "received":
          return "mdi-clock-time-five-outline";
        case "accepted":
          return "mdi-check-circle-outline";
        case "declined":
          return "mdi-close-circle-outline";
      }
      return "";
    } else if (inquiryItem.attachable_type === "App\\Models\\Form") {
      return "mdi-checkbox-blank-circle-outline";
    }
    return "";
  },
  statusText({ inquiryItem }) {
    if (inquiryItem.attachable_type === "App\\Models\\Document") {
      switch (inquiryItem.current_state) {
        case null:
          return "Ist hochzuladen";
        case "received":
          return "In Prüfung";
        case "accepted":
          return "Akzeptiert";
        case "declined":
          return "Abgelehnt";
      }
      return "";
    } else if (inquiryItem.attachable_type === "App\\Models\\Form") {
      return "Ist auszufüllen";
    }
    return "";
  },
  statusActionText({ inquiryItem }) {
    if (inquiryItem.attachable_type === "App\\Models\\Document") {
      if (inquiryItem.attachable && inquiryItem.attachable.is_outbound) {
        return "Herunterladen";
      } else {
        switch (inquiryItem.current_state) {
          case null:
            return this.$hasPermissions("consultant permission")
              ? "Ansehen"
              : "Hochladen";
        }
        return "Ansehen";
      }
    } else if (inquiryItem.attachable_type === "App\\Models\\Form") {
      return this.$hasPermissions("consultant permission")
        ? "Ansehen"
        : "Ausfüllen";
    }
    return "";
  },
  statusActionIcon({ inquiryItem }) {
    if (inquiryItem.attachable_type === "App\\Models\\Document") {
      if (inquiryItem.attachable && inquiryItem.attachable.is_outbound) {
        return "mdi-cloud-download";
      } else {
        switch (inquiryItem.current_state) {
          case null:
            return this.$hasPermissions("consultant permission")
              ? "mdi-eye"
              : "mdi-cloud-upload";
        }
        return "mdi-eye";
      }
    } else if (inquiryItem.attachable_type === "App\\Models\\Form") {
      return this.$hasPermissions("consultant permission")
        ? "mdi-eye"
        : "mdi-pencil";
    }
    return "";
  }
};
