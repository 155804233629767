<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        {{ inquiryItemProp.name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ inquiryItemProp.description }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action class="d-flex flex-row align-center">
      <div style="pointer-events: none;" class="mr-3">
        <!--if provided by bank maybe not necessary-->
        <v-btn text :color="statusColor">
          {{ statusText }}

          <v-icon right :color="statusColor">
            {{ statusIcon }}
          </v-icon>
        </v-btn>
      </div>

      <v-btn
        depressed
        min-width="200"
        :loading="downloadLoading"
        :disabled="isPreview"
        @click="
          downloadFile(
            inquiryItemProp.attachable.upload.id,
            inquiryItemProp.attachable.upload.filename_original
          )
        "
        v-if="
          inquiryItemProp.attachable && inquiryItemProp.attachable.is_outbound
        "
      >
        {{ statusActionText }}

        <v-badge
          :value="
            $hasPermissions('consultant permission')
              ? inquiryItemProp.notifications.length
              : null
          "
          :content="
            $hasPermissions('consultant permission')
              ? inquiryItemProp.notifications.length + ' Neu'
              : null
          "
        >
          <v-icon right>
            {{ statusActionIcon }}
          </v-icon>
        </v-badge>
      </v-btn>

      <v-btn
        depressed
        min-width="200"
        v-else
        :disabled="isPreview"
        :to="{
          name: 'InquiryItemsShow',
          params: { id: inquiryItemProp.id }
        }"
      >
        {{ statusActionText }}
        <v-badge
          :value="
            $hasPermissions('consultant permission')
              ? inquiryItemProp.notifications.length
              : null
          "
          :content="
            $hasPermissions('consultant permission')
              ? inquiryItemProp.notifications.length + ' Neu'
              : null
          "
        >
          <v-icon right>
            {{ statusActionIcon }}
          </v-icon>
        </v-badge>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    inquiryItemProp: Object,
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    downloadLoading: false
  }),
  computed: {
    statusColor() {
      if (this.inquiryItemProp) {
        if (this.inquiryItemProp.attachable_type === "App\\Models\\Document") {
          switch (this.inquiryItemProp.current_state) {
            case null:
              return "warning";
            case "received":
              return "info";
            case "accepted":
              return "success";
            case "declined":
              return "error";
          }
          return "warning";
        } else if (
          this.inquiryItemProp.attachable_type === "App\\Models\\Form"
        ) {
          return "warning";
        }
        return "";
      } else {
        return "";
      }
    },
    statusIcon() {
      if (this.inquiryItemProp) {
        if (this.inquiryItemProp.attachable_type === "App\\Models\\Document") {
          switch (this.inquiryItemProp.current_state) {
            case null:
              return "mdi-checkbox-blank-circle-outline";
            case "received":
              return "mdi-clock-time-five-outline";
            case "accepted":
              return "mdi-check-circle-outline";
            case "declined":
              return "mdi-close-circle-outline";
          }
          return "";
        } else if (
          this.inquiryItemProp.attachable_type === "App\\Models\\Form"
        ) {
          return "mdi-checkbox-blank-circle-outline";
        }
        return "";
      } else {
        return "";
      }
    },
    statusText() {
      if (this.inquiryItemProp) {
        if (this.inquiryItemProp.attachable_type === "App\\Models\\Document") {
          switch (this.inquiryItemProp.current_state) {
            case null:
              return "Ist hochzuladen";
            case "received":
              return "In Prüfung";
            case "accepted":
              return "Akzeptiert";
            case "declined":
              return "Abgelehnt";
          }
          return "";
        } else if (
          this.inquiryItemProp.attachable_type === "App\\Models\\Form"
        ) {
          return "Ist auszufüllen";
        }
        return "";
      } else {
        return "";
      }
    },
    statusActionText() {
      if (this.inquiryItemProp) {
        if (this.inquiryItemProp.attachable_type === "App\\Models\\Document") {
          if (
            this.inquiryItemProp.attachable &&
            this.inquiryItemProp.attachable.is_outbound
          ) {
            return "Herunterladen";
          } else {
            switch (this.inquiryItemProp.current_state) {
              case null:
                return this.$hasPermissions("consultant permission")
                  ? "Ansehen"
                  : "Hochladen";
            }
            return "Ansehen";
          }
        } else if (
          this.inquiryItemProp.attachable_type === "App\\Models\\Form"
        ) {
          return this.$hasPermissions("consultant permission")
            ? "Ansehen"
            : "Ausfüllen";
        }
        return "";
      } else {
        return "";
      }
    },
    statusActionIcon() {
      if (this.inquiryItemProp) {
        if (this.inquiryItemProp.attachable_type === "App\\Models\\Document") {
          if (
            this.inquiryItemProp.attachable &&
            this.inquiryItemProp.attachable.is_outbound
          ) {
            return "mdi-cloud-download";
          } else {
            switch (this.inquiryItemProp.current_state) {
              case null:
                return this.$hasPermissions("consultant permission")
                  ? "mdi-eye"
                  : "mdi-cloud-upload";
            }
            return "mdi-eye";
          }
        } else if (
          this.inquiryItemProp.attachable_type === "App\\Models\\Form"
        ) {
          return this.$hasPermissions("consultant permission")
            ? "mdi-eye"
            : "mdi-pencil";
        }
        return "";
      } else {
        return "";
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    downloadFile(id, name) {
      this.downloadLoading = true;
      this.axios
        .get("/upload/" + id + "/download", {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));
          const fileName = name;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
