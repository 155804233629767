<template>
  <div>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold"
              >Max Mustermann</v-list-item-title
            >
            <v-list-item-title>Anfrage XY</v-list-item-title>
            <v-list-item-subtitle class="black--text">
              Lorem ipsum. Lorem ipsum. Lorem ipsum.
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Letzte Aktualisierung: 13.02.2021
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-chip color="success" v-if="type === 'finished'">
              10/10
            </v-chip>
            <v-chip color="success" v-if="type === 'new'">
              3 Neu
            </v-chip>
            <v-chip color="error" v-if="type === 'old'">
              365 Tage
            </v-chip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: { type: String },
  data: () => ({
    open: 0,
    in_work: 0,
    finished: 0,
    total: 0
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    indexStartView() {
      this.axios
        .get("/dashboard/inquiries?view=start")
        .then(res => {
          this.open = res.data.open;
          this.in_work = res.data.in_work;
          this.finished = res.data.finished;
          this.total = res.data.total;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
