<template>
  <div>
    <div class="text-overline">Ihre Anfragen</div>
    <v-row v-if="assignedInquiries.length > 0">
      <v-col
        cols="12"
        md="3"
        v-for="inquiry in assignedInquiries.slice(0, 4)"
        :key="inquiry.id"
      >
        <util-inquiry-card :inquiryProp="inquiry" />
      </v-col>
    </v-row>
    <div v-else>
      Ihnen wurden keine Anfragen zugeordnet.
    </div>
    <div
      class="mt-4"
      v-if="assignedInquiries.length > 0 || assignedInquiries_draft.length > 0"
    >
      <v-btn color="primary" :to="{ name: 'InquiriesColumn' }">
        Alle aktiven anzeigen ({{ assignedInquiries.length }})
      </v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        text
        :to="{ name: 'InquiriesList', query: { type: 'draft' } }"
      >
        Alle Entwürfe anzeigen ({{ assignedInquiries_draft.length }})
      </v-btn>
      <v-btn text class="ml-2" :to="{ name: 'InquiriesForm' }">
        Neu anlegen
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </div>
    <div v-else class="mt-4">
      <v-btn color="primary" dark :to="{ name: 'InquiriesForm' }">
        Neu anlegen
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </div>

    <div class="mt-10">
      <div class="text-overline">Mit Ihnen geteilt</div>
      <v-row class="mt-4">
        <v-col cols="12" md="3" v-for="share in sharedWithUser" :key="share.id">
          <util-inquiry-card
            :inquiryProp="share.shareable"
            v-if="share.shareable_type === 'App\\Models\\Inquiry'"
            :isShare="true"
            :share="share"
          />
          <util-inquiry-item-card
            :inquiryItemProp="share.shareable"
            v-if="share.shareable_type === 'App\\Models\\InquiryItem'"
            :isShare="true"
            :share="share"
          />
        </v-col>
      </v-row>
      <!--      <div class="mt-4" v-if="sharedWithUser.length > 0">
        <v-btn
          color="primary"
          :to="{ name: 'InquiriesColumn', query: { type: 'sharedWith' } }"
        >
          Alle anzeigen ({{ sharedWithUser.length }})
        </v-btn>
      </div>-->
      <div v-if="sharedWithUser.length < 1" class="body-1 mt-4">
        Es wurden keine Anfragen mit Ihnen geteilt.
      </div>
    </div>
    <div class="mt-10">
      <div class="text-overline">Mit Team geteilt</div>
      <v-row class="mt-4">
        <v-col cols="12" md="3" v-for="share in sharedWithTeam" :key="share.id">
          <util-inquiry-card
            :inquiryProp="share.shareable"
            v-if="share.shareable_type === 'App\\Models\\Inquiry'"
            :isShare="true"
            :share="share"
          />
          <util-inquiry-item-card
            :inquiryItemProp="share.shareable"
            v-if="share.shareable_type === 'App\\Models\\InquiryItem'"
            :isShare="true"
            :share="share"
          />
        </v-col>
      </v-row>
      <!--      <div class="mt-4" v-if="sharedWithTeam.length > 0">
        <v-btn
          color="primary"
          :to="{ name: 'InquiriesColumn', query: { type: 'sharedWith' } }"
        >
          Alle anzeigen ({{ sharedWithUser.length }})
        </v-btn>
      </div>-->
      <div v-if="sharedWithTeam.length < 1" class="body-1 mt-4">
        Es wurden keine Anfragen mit einem Ihrer Teams geteilt.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    assignedInquiries: [],
    assignedInquiries_draft: [],
    sharedWithUser: [],
    sharedWithTeam: []
  }),
  computed: {
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  created() {
    this.showDashboardInquiries();
  },
  methods: {
    showDashboardInquiries() {
      this.axios
        .get("/dashboard/inquiries")
        .then(res => {
          this.assignedInquiries = res.data.assignedInquiries;
          this.assignedInquiries_draft = res.data.assignedInquiries_draft;
          this.sharedWithUser = res.data.sharedWithUser;
          this.sharedWithTeam = res.data.sharedWithTeam;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
