<template>
  <div>
    <draggable :list="formItems">
      <v-card
        flat
        color="grey lighten-4"
        v-for="(formItem, index) in formItems"
        :key="index"
        class="my-2"
      >
        <v-card-text class="d-flex justify-center">
          <v-btn icon style="cursor: move;">
            <v-icon>mdi-drag</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-text-field
            @keypress="$event.preventDefault()"
            filled
            v-if="formItem.type === 'text-field'"
            :label="formItem.name"
            :placeholder="formItem.description"
          />
          <v-textarea
            @keypress="$event.preventDefault()"
            filled
            v-if="formItem.type === 'textarea'"
            :label="formItem.name"
            :placeholder="formItem.description"
          />
          <v-select
            @keypress="$event.preventDefault()"
            filled
            v-if="formItem.type === 'select'"
            :label="formItem.name"
            :placeholder="formItem.description"
            :items="formItem.items"
          />
          <div>
            <v-btn block text @click="formItems.splice(index, 1)">
              Entfernen
              <v-icon right>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </draggable>
    <v-btn depressed block @click="formItemDialog = true">
      Feld hinzufügen
    </v-btn>
    <v-dialog v-model="formItemDialog" persistent max-width="500px">
      <util-form-item-form
        :key="formItemDialogKey"
        v-on:closeDialog="
          formItemDialog = false;
          formItemDialogKey++;
        "
        v-on:pushFormItem="
          formItems.push($event);
          formItemDialogKey++;
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  props: ["formItemsProp"],
  data: () => ({
    formItemDialog: false,
    formItemDialogKey: 0,
    formItems: []
  }),
  watch: {
    formItemsProp: function(val) {
      this.formItems = val;
    },
    formItems: function(val) {
      this.$emit("pass", val);
    }
  },
  mounted() {
    this.formItems = this.formItemsProp;
  },
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    formItemTypes() {
      return this.$store.getters["application/formItemTypes"];
    }
  },
  methods: {
    pushFormItem() {
      this.$emit("pushFormItem", this.formItem);
      this.$emit("closeDialog", this.formItem);
    },
    pushSelectItem() {
      this.formItem.push(this.selectItem);
      this.selectItem = null;
    }
  }
};
</script>

<style scoped></style>
