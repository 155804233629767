<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :text="isText"
        :color="isText ? '' : 'primary'"
        block
        v-bind="attrs"
        v-on="on"
      >
        {{ isText ? "Weitere Unterlage hochladen" : "Unterlage hochladen" }}
        <v-icon right :dark="!isText">mdi-upload-outline</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Unterlage hochladen">
      <template v-slot:text>
        <v-fade-transition>
          <div key="notUploaded" v-if="!isUploaded">
            <ui-upload-form
              @input="file = $event"
              :file-prop="file"
              :key="uploadFormKey"
            />
            <v-slide-y-transition>
              <v-form
                @submit.prevent="submit"
                v-model="isValid"
                v-if="!loading && file"
                class="mt-4"
              >
                <div class="mb-1 text-h6">
                  {{ file.name }}
                </div>
                <div class="mb-2">
                  Geben Sie der Datei eine Bezeichnung, sodass Sie leichter
                  zuzuordnen ist.
                </div>
                <v-text-field
                  filled
                  v-model="name"
                  :rules="[rules.required, rules.short_text]"
                  label="Bezeichnung*"
                  placeholder="z. B. Steuererklärung 2019, Personalausweis Vorne"
                />
              </v-form>
              <div v-if="loading && file">
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ file.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-progress-linear
                  v-model="uploadProgress"
                  color="secondary"
                  height="25"
                >
                  {{ uploadProgress }}%
                </v-progress-linear>
              </div>
            </v-slide-y-transition>
          </div>
          <div key="uploaded" v-else>
            <sweetalert-icon icon="success" />
            <div class="text-h6 text-center">
              Die Unterlage wurde erfolgreich hochgeladen!
            </div>
          </div>
        </v-fade-transition>
      </template>

      <template v-slot:actions>
        <v-fade-transition>
          <div key="notUploadedButtons" style="width: 100%" v-if="!isUploaded">
            <v-btn
              color="primary"
              block
              @click="submit"
              :disabled="!isValid || !file"
              :loading="loading"
            >
              Hochladen
              <v-icon right dark>
                mdi-upload-outline
              </v-icon>
            </v-btn>
            <v-btn text @click="close" block>
              Abbrechen
            </v-btn>
          </div>
          <div key="uploadedButtons" style="width: 100%" v-else>
            <v-btn color="primary" @click="close" block>
              Okay
            </v-btn>
          </div>
        </v-fade-transition>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    isText: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    item: {},
    file: null,
    name: "",
    // functional
    dialog: false,
    isValid: false,
    loading: false,

    uploadFormKey: 0,
    uploadProgress: 0,

    isUploaded: false
  }),
  computed: {
    uploadRequest() {
      return this.$store.getters["inquiryItem/inquiryItem"]
        .currentUploadRequest;
    },
    inquiryItem() {
      return this.$store.getters["inquiryItem/inquiryItem"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      showInquiry: "currentInquiry/showInquiry",
      showInquiryItem: "inquiryItem/show"
    }),

    storeFile() {
      this.loading = true;
      let formData = new FormData();

      formData.append("file", this.file);
      formData.append("name", this.name);

      this.axios
        .post("upload/request/" + this.uploadRequest.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadProgress = Math.round(
              (100 * progressEvent.loaded) / progressEvent.total
            );
          }.bind(this)
        })
        .then(r => {
          this.$snotify.success("Das Dokument wurde erfolgreich hochgeladen.");
          this.isUploaded = true;
          this.uploadRequest.uploads.push(r.data.upload);
          this.showInquiry(this.inquiryItem.inquiry.id);
          this.showInquiryItem({ id: this.inquiryItem.id });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.file = null;
      this.uploadFormKey++;
      this.uploadProgress = 0;
      this.isUploaded = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.storeFile();
    }
  }
};
</script>

<style scoped></style>
